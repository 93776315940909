@tailwind base;
@tailwind components;
@tailwind utilities;

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: "Noto Sans", sans-serif, Roboto, "Helvetica Neue", Arial,
    "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol", "Noto Color Emoji";
}

body {
    padding: 0;
}

.help-bubble {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 10px;
    z-index: 1;
    width: 200px;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    margin-top: 5px;
    font-size: 0.8rem;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
  }
  
  @media (hover: hover) {
    .group:hover .help-bubble {
      display: block;
    }
  }
  
  @media (hover: none) {
    .group:active .help-bubble {
      display: block;
    }
  }

@keyframes pulse {
    0% {
        transform: scale(1);
        box-shadow: 0 0 0 0 rgba(255, 0, 0, 0.7);
    }
    70% {
        transform: scale(1.05);
        box-shadow: 0 0 0 10px rgba(255, 0, 0, 0);
    }
    100% {
        transform: scale(1);
        box-shadow: 0 0 0 0 rgba(255, 0, 0, 0);
    }
}

.pulse-animation {
    animation: pulse 1.5s infinite;
}
